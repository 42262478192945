.sendIcon, .filesIcon {
    width: 20px;
    height: 20px;
    background: none;
}

.sendBtn {
    border-radius: 20px;
    width: 80px;
}

.fileBtn {

}