.usersListWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 120px;
}

.userCard {
    margin: 4px 20px;
    max-width: 350px;
    border-radius: 25px;
    border: black solid 1px;
    background: #213054;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.userCard {
    justify-content: center;
}

.userCardLabel {
    font-family: Montserrat;
    font-size: 16px;
    margin: 12px;
    color: white;
}