.broadcastWrapper {
    font-family: Montserrat;
    margin-top: 100px;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}

.broadcastTitle {
    text-align: center;
}

.broadcastInput {
    padding: 10px;
    margin: 40px 0;
    width: 80%;
    border: black solid 1px;
    background: #273951;
    border-radius: 15px;
}

.broadcastSelect {
    color: black;
    background: #273951;
    border-radius: 15px;
    font-size: 20px;
}

.removeFileIcon {
    max-height: 80px;
}

.inputChatFilePreview {
    max-width: 50%;
    max-height: 256px;
    border: black solid 2px;
}

.adm-list {
    background-color: #2b3953;
}

.buttons-row {
    font-family: Montserrat;
    width: 100%;
    display: flex;
    justify-content:  space-between;
    flex-wrap: wrap;
    padding-top: 40px;
}

.networks-list-item {
    padding-left: 40px;
    padding-top: 16px;
}