html, body {
    height: 100%;
}

body {
    width: 100%;
    margin: 0;
    height: 100%;
}

.loginPage_form {}

.loginPage {
    justify-content: center;
    display: flex;
    position: absolute;
    padding: 50px 0;
    min-height: 100%;
    width: 100%;
    background: linear-gradient(161.3deg,#015d7b,#052b43 50%,#4f3265);
    color: white;
}

.loginPageLabel {
    padding-bottom: 10px;
}

.loginPage .inputbox {
    width: 100%;
    height: 50px;
    margin-top: 44px;
    margin-bottom: 44px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.6);
}
.loginPage .inputbox input {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    outline: none;
    background: none;
    padding: 10px;
    font-size: 1.2em;
    color: white;
}

.loginFormSubmit {
    width: 100%;
    background: linear-gradient(60.16deg, #00B8B0 5.38%, #0795CF 142.65%);
    color: #fff;
    border: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.loginFormSubmit:hover {
    background: #23BCFA;
}

.loginFormSubmit:active {
    background: #219DD5;
}