.statsWrapper {
    margin-top: 120px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    color: white;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: start;
    margin-bottom: 20px;
}

.statsCard {
    margin: 20px;
    padding: 12px;
    display: flex;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    background: #213054;
    backdrop-filter: blur(30px);
}

@media screen and (max-width: 900px) {
    .statsCard {
        width: 100%;
    }
}

.statsCardTitle {
    margin: 20px auto;
    text-align: center;
}


.statsCardValue {
    margin: 20px auto;
    font-size: 32px;
}

.statsFilters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.statsSelect {
    height: 40px;
    color: black;
    margin-top: 40px;
    min-width: 160px;
}

.btnFilters {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 16px;
    background: transparent;
    border: 2px solid #39D2D1;
    box-sizing: border-box;
    border-radius: 15px;
    text-align: center;
    color: white;
    padding: 12px;
}

.btnFilters:active {
    background: #23BCFA;
}

.datetimePicker {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-around;
    justify-content: center;
}