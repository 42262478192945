.header {
    background: rgb(43, 57, 83);
    display: block;
    align-items: center;

    width: 100%;
    height: 66px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    z-index: 100;
}

.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
}

.bm-item:hover {
    color: white;
}

.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    left: 18px;
    top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    left: 168px;
    position: absolute;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #ffffff;
}

.bm-menu-wrap {
    width: 200px;
}

/* General sidebar styles */
.bm-menu {
    background: linear-gradient(161.3deg, #015D7B 0%, #052B43 50%, #4F3265 100%);
    backdrop-filter: blur(30px);
    padding: 2.5em 1.5em 0;
    width: 200px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #ffffff;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}