.langsWrapper {
    margin-top: 120px;
}

.listItem {
    background: #213054;
    backdrop-filter: blur(30px);
    border: none;
}

.adm-text-area, .adm-button  {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    color: white;
}

.block {
    display: flex;
    flex: available;
    margin: auto;
}
