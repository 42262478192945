
.drop-down-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 20px;
}

.drop-down-children-row {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
}

.drop-down-title-text {
    padding-left: 20px;
}

.drop-down-children {
    padding-top: 20px;
    padding-left: 32px;
}

.drop-down-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: white;
    border: 2px solid #2b3953;
    padding: 12px;
}