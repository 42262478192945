.rce-mbox-file {
    padding-bottom: 13px;
    display: flex;
    flex-direction: column;
}

.rce-mbox-file > button {
    background: #e9e9e9;
    display: flex;
    border-radius: 5px;
    margin: -3px -6px 4px;
    align-items: center;
    min-height: 52px;
    max-width: 500px;
    padding: 5px 0;
    cursor: pointer;
    user-select: none;
    outline: none;
    border:none;
}

.rce-mbox-file > button > * {
    padding: 0px 10px;
}

.rce-mbox-file--icon {
    font-size: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.rce-mbox-file--size {
    font-size: 10px;
    margin-top: 3px;
    max-width: 52px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--text {
    font-size: 13.6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--buttons {
    font-size: 30px;
    align-items: center;
    display: flex;
}
.rce-mbox-file--buttons .rce-error-button {
    display: inherit;
}

.rce-mbox-file--loading {
    font-size: 15px;
    width: 40px;
    height: 40px;
}