html {
    font-family: Montserrat;
    background: linear-gradient(180deg, #172342 0%, #172342 100%);
}


body {
}

.mainContentLayout {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.listWithData {
    margin: 40px 0;
    width: 100%;
    /*border-radius: 15px;*/
    border: none;
}

.forAllScreen {
    margin: 20px;
    border-radius: 25px;
    padding: 8px;
}

.svgButton {
    width: 20px;
    height: 20px;
}

.btnWithoutFill, .--adm-font-family {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    padding: 16px;
    font-size: 22px;
    line-height: 22px;
    background: transparent;
    border: 2px solid #39D2D1;
    box-sizing: border-box;
    border-radius: 15px;
    text-align: center;
    color: white;
    /*padding: 20px;*/
}

.btnWithoutFill:hover {
    background: #134072;
}

.btnWithoutFill:active {
    background: #23BCFA;
}


::-webkit-scrollbar {
    position: fixed;
    width: 1px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #273951;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}