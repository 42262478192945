.userDetailPage {
    margin-top: 120px;
    justify-content: center;
    display: flex;
    padding-bottom: 50px;
    border: black solid 1px;
    background: #213054;
    color: white;
}


.inputBoxWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 800px;
    padding: 20px;
    justify-content: center;
}


.userDetailSubmit {
    width: 100%;
    background: linear-gradient(60.16deg, #00B8B0 5.38%, #0795CF 142.65%);
    color: #fff;
    border: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.userDetailSubmit:hover {
    background: #23BCFA;
}

.userDetailSubmit:active {
    background: #219DD5;
}

.userDetailDelete {
    margin-top: 20px;
    background: linear-gradient(60.16deg, #c71111 5.38%, #ff3c00 142.65%);
    color: #fff;
    border: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
}

.userDetailDelete:hover {
    background: #ff1515;
}

.userDetailDelete:active {
    background: #ff3c00;
}

.userDetailPage .inputbox {
    height: 50px;
    margin-top: 44px;
    margin-bottom: 44px;
    padding: 20px;
    font-size: 14px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.6);
}

.userDetailPage .inputbox input {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    outline: none;
    background: none;
    padding: 10px;
    font-size: 1.2em;
    color: white;
}

.userDetailCheckbox {
    width: 40px;
    height: 40px;
    margin: auto;
}

.userLabel {
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.withLabel {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    font-size: 20px;
    width: 100%;
    padding-bottom: 16px;
}

.select {
    color: black;
    min-width: 200px;
}