.adm-search, .adm-search-input-box, .adm-input-wrapper, .adm-search-input, .adm-input, .adm-search .adm-search-input-box {
    background: none;
    color: white;
}

.adm-search .adm-search-input-box .adm-search-input-box-icon {
    color: white;
}

.adm-search-input {
    border-bottom: 2px solid #273951;
    color: white;
}

.phrasesList {
    margin-top: 120px;
}